<template>
	<div class="form-group">
		<h4>{{ $t('planes.profileTitle') }}</h4>
		<div class="row preview">
			<div class="col-sm-6 form-group">
				<label for="speedUnit">{{ $t('planes.speedUnit') }}: *</label>
				<AutoComplete id="speedUnit" :options="speedUnits" :msgs="msgs" keyProp="key"
					:labelFunc="(option) => $t(option.name)" v-model="planeToSave.speedUnit" />
				<ComponentMessage :msgs="msgs" forComponent="speedUnit" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="defaultSpeed">{{ $t('planes.defaultSpeed') }}: *</label>
				<TextInput id="defaultSpeed" v-model="selectedDefaultSpeed" :msgs="msgs" @change="onDefaultSpeedChanged()"
					@keypress="onlyNumbers" :maxlength="'4'" class="form-control" />
				<ComponentMessage :msgs="msgs" forComponent="defaultSpeed" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="altitudeUnit">{{ $t('planes.altitudeUnit') }}: *</label>
				<AutoComplete id="altitudeUnit" :options="altitudeUnits" :msgs="msgs" keyProp="key"
					:labelFunc="(option) => $t(option.name)" v-model="planeToSave.altitudeUnit" />
				<ComponentMessage :msgs="msgs" forComponent="altitudeUnit" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="defaultAltitude">{{ $t('planes.defaultAltitude') }}: *</label>
				<TextInput id="defaultAltitude" v-model="selectedDefaultAltitude" :msgs="msgs"
					@change="onDefaultAltitudeChanged()" @keypress="onlyNumbers" class="form-control" />
				<ComponentMessage :msgs="msgs" forComponent="defaultAltitude" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="consumptionUnit">{{ $t('planes.consumptionUnit') }}: *</label>
				<AutoComplete id="consumptionUnit" :options="consumptionUnits" :msgs="msgs" keyProp="key"
					:labelFunc="(option) => $t(option.name)" v-model="planeToSave.consumptionUnit" />
				<ComponentMessage :msgs="msgs" forComponent="consumptionUnit" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="defaultConsumption">{{ $t('planes.defaultConsumption') }}: *</label>
				<TextInput id="defaultConsumption" v-model="selectedDefaultConsumption" :msgs="msgs"
					@change="onDefaultConsumptionChanged()" @keypress="onlyNumbers" maxlength="4" class="form-control" />
				<ComponentMessage :msgs="msgs" forComponent="defaultConsumption" />
			</div>
		</div>
	</div>
</template>

<script>
import AutoComplete from "@/components/inputs/AutoComplete.vue";
import units from "@/plugins/units";

export default {
	name: "CustomPlaneFormProfile",
	components: { AutoComplete },
	props: {
		msgs: Object,
		planeToSave: Object
	},
	units: units,
	data() {
		return {
			selectedDefaultSpeed: this.planeToSave.defaultSpeed,
			selectedDefaultAltitude: this.planeToSave.defaultAltitude,
			selectedDefaultConsumption: this.planeToSave.defaultConsumption
		}
	},
	computed: {
		consumptionUnits: () => {
			return Object.values(units.consumption)
		},
		altitudeUnits: () => {
			return [
				units.distance.ft,
				units.distance.m,
				units.distance.fl
			]
		},
		speedUnits: () => {
			return Object.values(units.speed)
		}
	},
	watch: {
		planeToSave() {
			const defaultSpeed = String(this.planeToSave.defaultSpeed).padStart(4, '0')
			this.selectedDefaultSpeed = defaultSpeed === '0000' ? '' : defaultSpeed

			const isFlightLevel = this.planeToSave.altitudeUnit == 'fl';
			const defaultAltitude = String(this.planeToSave.defaultAltitude).padStart(isFlightLevel ? 3 : 4, '0')
			this.selectedDefaultAltitude = defaultAltitude === '0000' ? '' : defaultAltitude

			let defaultConsumption;
			if (this.planeToSave.defaultConsumption) {
				defaultConsumption = String(this.planeToSave.defaultConsumption);
			}
			else {
				defaultConsumption = '';
			}
			this.selectedDefaultConsumption = defaultConsumption === '0000' ? '' : defaultConsumption
		}
	},
	methods: {
		getDefaultAltitudeMaxLength() {
			if (this.selectedAltitudeUnit && this.selectedAltitudeUnit.id === 2) {
				return "4"
			} else {
				return "3"
			}
		},
		onlyNumbers(evt) {
			if (/^[0-9]+$/.test(evt.key)) return true;
			else evt.preventDefault();
		},
		onDefaultSpeedChanged() {
			if (this.selectedDefaultSpeed) {
				this.planeToSave.defaultSpeed = Number(this.selectedDefaultSpeed)
			}
		},
		onDefaultAltitudeChanged() {
			if (this.selectedDefaultAltitude) {
				this.planeToSave.defaultAltitude = Number(this.selectedDefaultAltitude)
			}
		},
		onDefaultConsumptionChanged() {
			if (this.selectedDefaultConsumption) {
				this.planeToSave.defaultConsumption = Number(this.selectedDefaultConsumption)
			}
		},
	}
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
}
</style>
