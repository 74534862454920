<template>
	<div class="form-group">
		<h4>{{ $t('planes.equipments') }}</h4>
		<h5>{{ $t('planes.instruments') }}</h5>
		<div class="row preview">

			<div class="col-sm-12 form-group">
				<label for="speedUnit">{{ $t('planes.icaoEquipment') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.icaoEquipments" :options="icaoEquipments" optionLabel="value" optionValue="code" placeholder="Select equipment" style="width: 100%"/>
					<plane-change-indicator field="icaoEquipments" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>

			<div class="col-sm-12 form-group">
				<label for="speedUnit">{{ $t('planes.icaoSurveillance') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.icaoSurveillances" :options="icaoSurveillances" optionLabel="value" optionValue="code" placeholder="Select surveillance code" style="width: 100%"/>
					<plane-change-indicator field="icaoSurveillances" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>

			</div>

			<div class="col-sm-12 form-group" v-show="equipmentContainsPBN">
				<label for="speedUnit">{{ $t('planes.icaoPerfBasedNav') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.icaoPerfBasedNavList" :options="icaoPerfBasedNavList" optionLabel="value" optionValue="code" placeholder="Select ICAO Perf-Based Nav"  style="width: 100%"/>
					<plane-change-indicator field="icaoPerfBasedNavList" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import PlaneChangeIndicator from "@/components/plane/PlaneChangeIndicator.vue";

export default {
	name: "CustomPlaneFormEquipments",
	components: { PlaneChangeIndicator, MultiSelect},
	props: {
		msgs: Object,
		planeToSave: Object,
		planeChanges: Array,
		isEditing: Boolean,
		isMainEditor: Boolean,
	},
	methods: {
		resetChange(...p) {
			this.$emit('resetChange', ...p);
		},
	},
	data() {
		let defaultIcaoEquipment =  {code: 'N', value: 'N-NIL'}
		let defaultIcaoSurveillance = {code: 'N', value: "N-NIL"}
		return {
			defaultIcaoEquipment: defaultIcaoEquipment,
			equipmentContainsPBN: false,
			icaoEquipments: [
				{code: 'N', value: 'N-NIL'},
				{code: 'A', value: 'A-GBAS Landing Sys'},
				{code: 'B', value: 'B-LPV (APV with SBAS)'},
				{code: 'C', value: 'C-LORAN C'},
				{code: 'D', value: 'D-DME'},
				{code: 'E1', value: 'E1-FMC WPR ACARS'},
				{code: 'E2', value: 'E2-D-FIS ACARS'},
				{code: 'E3', value: 'E3-PDC ACARS'},
				{code: 'F', value: 'F-ADF'},
				{code: 'G', value: 'G-GNSS'},
				{code: 'H', value: 'H-HF RTF'},
				{code: 'I', value: 'I-Inertial Nav.'},
				{code: 'J1', value: 'J1-CPDLC ATN DL Mode 2'},
				{code: 'J2', value: 'J2-CPDLC FANS 1/A HFDL'},
				{code: 'J3', value: 'J3-CPDLC FANS 1/A VDL Mode A'},
				{code: 'J4', value: 'J4-CPDLC FANS 1/A VDL Mode 2'},
				{code: 'J5', value: 'J5-CPDLC FANS 1/A (INMARSAT)'},
				{code: 'J6', value: 'J6-CPDLC FANS 1/A (MTSAT)'},
				{code: 'J7', value: 'J7-CPDLC FANS 1/A (Iridium)'},
				{code: 'K', value: 'K-MLS'},
				{code: 'L', value: 'L-ILS'},
				{code: 'M1', value: 'M1-ATC RTF (INMARSAT)'},
				{code: 'M2', value: 'M2-ATC RTF (MTSAT)'},
				{code: 'M3', value: 'M3-ATC RTF (Iridium)'},
				{code: 'O', value: 'O-VOR'},
				{code: 'P1', value: 'P1-CPDLC RCP 400'},
				{code: 'P2', value: 'P2-CPDLC RCP 240'},
				{code: 'P3', value: 'P3-SATVOICE RCP 400'},
				{code: 'R', value: 'R-PBN Approved'},
				{code: 'S', value: 'S-(VOR, VHF RTF, ILS)'},
				{code: 'T', value: 'T-TACAN'},
				{code: 'U', value: 'U-UHF RTF'},
				{code: 'V', value: 'V-VHF RTF'},
				{code: 'W', value: 'W-RVSM'},
				{code: 'X', value: 'X-MNPS or NAT HLA Approved'},
				{code: 'Y', value: 'Y-VHF 8.33 kHz spacing'},
				{code: 'Z', value: 'Z-Other'},
			],
			defaultIcaoSurveillance: defaultIcaoSurveillance,
			icaoSurveillances: [
				{code: 'N', value: "N-NIL"},
				{code: 'A', value: "A-Mode A"},
				{code: 'B1', value: "B1-ADS-B, Dedicated 1090 Out"},
				{code: 'B2', value: "B2-ADS-B, Dedicated 1090 Out+In"},
				{code: 'C', value: "C-Modes A and C"},
				{code: 'D1', value: "D1-ADS-C, FANS"},
				{code: 'E', value: "E-Mode S, ID, Alt, Squitter"},
				{code: 'G1', value: "G1-ADS-C, ATN"},
				{code: 'H', value: "H-Mode S, ID, Alt, Enhanced Surv"},
				{code: 'I', value: "I-Mode S, ID no Alt"},
				{code: 'L', value: "L-Mode S, ID, Alt, Squitter+Enh Surv"},
				{code: 'P', value: "P-Mode S, Alt no ID"},
				{code: 'S', value: "S-Mode S, ID and Alt"},
				{code: 'U1', value: "U1-ADS-B, UAT Out"},
				{code: 'U2', value: "U2-ADS-B, UAT Out+In"},
				{code: 'V1', value: "V1-ADS-B, VDL Mode 4 Out"},
				{code: 'V2', value: "V2-ADS-B, VDL Mode 4 Out+In"},
				{code: 'X', value: "X-Mode S, no ID no Alt"},
			],
			defaultIcaoPerfBasedNav: {},
			icaoPerfBasedNavList: [
				{code: 'A1', value: 'A1-RNAV 10 (RNP10)'},
				{code: 'B1', value: 'B1-RNAV 5 All Sensors'},
				{code: 'B2', value: 'B2-RNAV 5 GNSS'},
				{code: 'B3', value: 'B3-RAV 5 DME/DME'},
				{code: 'B4', value: 'B4-RNAV 5 VOR/DME'},
				{code: 'B5', value: 'B5-RNAV 5 INS/IRS'},
				{code: 'B6', value: 'B6-RNAV 5 LORAN C'},
				{code: 'C1', value: 'C1-RNAV 2 All Sensors'},
				{code: 'C2', value: 'C2-RNAV 2 GNSS'},
				{code: 'C3', value: 'C3-RNAV 2 DME/DME'},
				{code: 'C4', value: 'C4-RNAV 2 DME/IRU'},
				{code: 'D1', value: 'D1-RNAV 1 All Sensors'},
				{code: 'D2', value: 'D2-RNAV 1 GNSS'},
				{code: 'D3', value: 'D3-RNAV 1 DME/DME'},
				{code: 'D4', value: 'D4-RNAV 1 DME/IRU'},
				{code: 'L1', value: 'L1-RNP 4'},
				{code: 'O1', value: 'O1-RNP 1 All Sensors'},
				{code: 'O2', value: 'O2-RNP 1 GNSS'},
				{code: 'O3', value: 'O3-RNP 1 DME/DME'},
				{code: 'O4', value: 'O4-RNP 1 DME/IRU'},
				{code: 'S1', value: 'S1-RNP APCH'},
				{code: 'S2', value: 'S2-RNP APCH & Baro'},
				{code: 'T1', value: 'T1-RNP AR APCH & RF'},
				{code: 'T2', value: 'T2-RNP AR APCH no RF'},
			]
		}
	}
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
}
</style>
