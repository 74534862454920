<template>
	<div class="form-group">
		<h5>{{ $t('planes.emergencyInstruments') }}</h5>
		<div class="row preview">

			<div class="col-sm-12 form-group">
				<label for="speedUnit">{{ $t('planes.emergencyRadio') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.emergencyRadios" :options="emergencyRadios" optionLabel="name" optionValue="name" placeholder="Select emergency radio" style="width: 100%"/>
					<plane-change-indicator field="emergencyRadios" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>

			<div class="col-sm-12 form-group">
				<label for="speedUnit">{{ $t('planes.survivalEquipment') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.survivalEquipments" :options="survivalEquipments" optionLabel="name"  optionValue="name" placeholder="Select survival equipment" style="width: 100%"/>
					<plane-change-indicator field="survivalEquipments" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>

			<div class="col-sm-12 form-group">
				<label for="speedUnit">{{ $t('planes.jackets') }}: *</label>
				<div class="w-100 d-flex">
					<MultiSelect v-model="planeToSave.jackets" :options="jackets" optionLabel="name" optionValue="name" placeholder="Select Jackets" style="width: 100%"/>
					<plane-change-indicator field="jackets" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import PlaneChangeIndicator from "@/components/plane/PlaneChangeIndicator.vue";

export default {
	name: "CustomPlaneFormEmergencyInstruments",
	components: { PlaneChangeIndicator, MultiSelect},
	props: {
		msgs: Object,
		planeToSave: Object,
		planeChanges: Array,
		isEditing: Boolean,
		isMainEditor: Boolean,
	},
	computed: {
		computedEmergencyRadios: {
			get() {
				if (this.planeToSave.emergencyRadios) {
					let a = this.planeToSave.emergencyRadios.filter(radio => radio.name)
					return this.selectedEmergencyRadios.filter(radio => radio.name)
				}
				return [];
			},
			set(value) {
				this.selectedEmergencyRadios = value
				this.planeToSave.emergencyRadios = value.map(radio => radio.name)
			}
		},
		computedSurvivalEquipments: {
			get() {
				if (this.planeToSave.survivalEquipments) {
					return this.selectedSurvivalEquipments.filter(s => s.name)
				}
				return [];
			},
			set(value) {
				this.selectedSurvivalEquipments = value
				this.planeToSave.survivalEquipments = value.map(se => se.name)
			}
		},
		computedJackets: {
			get() {
				if (this.planeToSave.jackets) {
					return this.selectedJackets.filter(jacket => jacket.name)
				}
				return [];
			},
			set(value) {
				this.selectedJackets = value
				this.planeToSave.jackets = value.map(jacket => jacket.name)
			}
		}
	},
	methods: {
		resetChange(...p) {
			this.$emit('resetChange', ...p);
		},
	},
	data() {
		return {
			emergencyRadios: [
				{name: 'UHF'},
				{name: 'VHF'},
				{name: 'ELT'}
			],
			survivalEquipments: [
				{name: 'Polar'},
				{name: 'Desert'},
				{name: 'Maritime'},
				{name: 'Jungle'},
			],
			jackets: [
				{name: 'Light'},
				{name: 'Floures'},
				{name: 'UHF'},
				{name: 'VHF'},
			]
		}
	}
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
}
</style>
