<template>
	<div class="form-group">
		<h4>{{ $t('planes.plane') }}</h4>
		<div class="row preview">
			<div class="col-sm-12 form-group">
				<label for="registrationNumber">{{ $t('planes.registrationNumber') }}: *
					<span class="text-muted" v-if="!isEditing">({{$t("planes.cantEdit")}})</span>
				</label>
				<TextInput id="registrationNumber" :disabled="isEditing" v-model="planeToSave.registrationNumber"
					:msgs="msgs" class="form-control" maxlength="7" @keypress="onlyNumbersAndAlphabets" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="registrationNumber" />
			</div>


			<div class="col-sm-6 form-group">
				<label for="operator">
					{{ $t('planes.operator') }}: *
					<span class="text-muted" v-if="!isEditing">({{$t("planes.cantEdit")}})</span>
				</label>
				<div class="w-100 d-flex">
					<TextInput id="operator" v-model="planeToSave.operator" :disabled="isEditing" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="operator" />
			</div>
			<div class="col-sm-6 form-group">
				<label for="operatorEmail">
					{{ $t('planes.operatorEmail') }}: *
					<span class="text-muted" v-if="!isEditing">({{$t("planes.cantEdit")}})</span>
				</label>
				<div class="w-100 d-flex">
					<AutoComplete class="w-100 full-auto-complete" @item-select="onSelectOperator"  v-model="planeToSave.operatorEmail" field="email" :suggestions="suggestedOperators" @complete="searchOperators" :disabled="noOperatorEmail ? !isMainEditor : isEditing" :required="noOperatorEmail && isMainEditor">
						<template #item="option">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex justify-content-start align-items-center gap-2">
									<Avatar :user="option.item" class="avatar" />
									{{option.item.name || option.item.username}}
								</div>
								<div>{{option.item.email}}</div>
							</div>
						</template>
					</AutoComplete>
				</div>
				<ComponentMessage :msgs="msgs" forComponent="operatorEmail" />
			</div>


			<div class="col-sm-6 form-group ">
				<label for="producer">{{ $t('planes.producer') }}:</label>
				<div class="w-100 d-flex">
					<TextInput id="producer" v-model="planeToSave.producer" :msgs="msgs" class="form-control flex-grow-1" />
					<plane-change-indicator field="producer" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="producer" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="model">{{ $t('planes.model') }}:</label>
				<div class="w-100 d-flex">
					<TextInput id="model" v-model="planeToSave.model" :msgs="msgs" class="form-control" />
					<plane-change-indicator field="model" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="model" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="planeModel">{{ $t('planes.planeModel') }}: *</label>
				<div class="w-100 d-flex">
					<TextInput id="planeModel" v-model="planeToSave.planeModel" :msgs="msgs" @keypress="onlyNumbersAndAlphabets"
						maxlength="4" class="form-control" />
					<plane-change-indicator field="planeModel" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="planeModel" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="colorAndNotation">{{ $t('planes.colorAndNotation') }}: *</label>
				<div class="w-100 d-flex">
					<TextInput id="colorAndNotation" v-model="planeToSave.colorAndNotation" :msgs="msgs" class="form-control" />
					<plane-change-indicator field="colorAndNotation" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="colorAndNotation" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="turbulenceCategory">{{ $t('planes.turbulenceCategory') }}: *</label>
				<div class="w-100 d-flex">
					<select class="media form-control" :style="{ width: '100%', height: '50%' }"
						v-model="planeToSave.turbulenceCategory" id="turbulenceCategory">
						<option value="nil">{{ $t('auto-complete.please-choose') }} </option>
						<option>L</option>
						<option>M</option>
						<option>N</option>
					</select>
					<plane-change-indicator field="turbulenceCategory" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="turbulenceCategory" />
			</div>


			<div class="col-sm-6 form-group">
				<label for="beaconType">{{ $t('planes.beaconType') }}: *</label>
				<div class="w-100 d-flex">
					<select class="media form-control" :style="{ width: '100%', height: '50%' }" v-model="planeToSave.beaconType"
									id="beaconType">
						<!-- IF YOU ADD OR REMOVE FROM THIS ALSO CHANGE THIS IN THE StartFlightView.vue FILE -->
						<option value="MOTORPLANE">{{ $t('beaconType.MOTORPLANE') }}</option>
						<option value="GLIDER">{{ $t('beaconType.GLIDER') }}</option>
						<option value="PARA_GLIDER">{{ $t('beaconType.PARA_GLIDER') }}</option>
						<option value="HELICOPTER">{{ $t('beaconType.HELICOPTER') }}</option>
						<option value="CAR">{{ $t('beaconType.CAR') }}</option>
					</select>
					<plane-change-indicator field="beaconType" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="beaconType" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PlaneChangeIndicator from "../PlaneChangeIndicator.vue";
import AutoComplete from "primevue/autocomplete";
import Avatar from "@/components/profile/avatar/Avatar.vue";

export default {
	name: "CustomPlaneFromPlane",
	components: { AutoComplete, PlaneChangeIndicator, Avatar },
	props: {
		msgs: Object,
		planeToSave: Object,
		planeChanges: Array,
		isEditing: Boolean,
		isMainEditor: Boolean,
	},
	data() {
		return {
			selectedTurbulenceCategory: null,
			turbulenceCategories: [{ name: 'L' }, { name: 'M' }, { name: 'H' }],
			suggestedOperators: [],
			noOperatorEmail: !(this.planeToSave.operatorEmail),
		}
	},
	computed: {
		...mapGetters([
			'getPlaneToSave'
		])
	},
	watch: {
		getPlaneToSave(plane) {
			this.planeToSave = plane;
		}
	},
	methods: {
		onSelectOperator(e) {
			this.planeToSave.operatorEmail = e.value.email
		},
		resetChange(...p) {
			this.$emit('resetChange', ...p);
		},
		onlyNumbersAndAlphabets(evt) {
			if (/^[A-Za-z0-9]+$/.test(evt.key)) return true;
			else evt.preventDefault();
		},
		selectTurbulenceCategory() {
			if (this.selectedTurbulenceCategory) {
				this.planeToSave.turbulenceCategory = this.selectedTurbulenceCategory.name
			}
		},
		async searchOperators({ query }) {
			this.suggestedOperators = await this.$rest.getOperators(query);
		}
	}
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
}

.avatar {
	width: 32px;
	height: 32px;
}

#registrationNumber {
	text-transform: uppercase;
}

</style>
