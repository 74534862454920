<template>
	<div class="media">
		<div class="media-prepend" :style="{ width: opened === false ? '40px' : 'calc(100% - 40px)' }" @click="open">
			<font-awesome-icon icon="search" size="lg" />
			<input ref="filter" type="text" class="form-control" @keyup="filter($event)" @blur="close" v-model="filterText" :class="{ opened: opened }" />
		</div>
		<div class="media-body" style="position: relative">
			<div class="select-overlay" @click="close" v-if="opened">
				<font-awesome-icon icon="check" size="lg" />
			</div>
			<select ref="select" class="form-control" v-model="selectValue" @change="emitChange">
				<option :value="null">{{ $t('auto-complete.please-choose') }}</option>
				<option v-for="o in filteredOptions" :key="o[keyProp]" :value="o[keyProp]">
					<template v-if="labelFunc">
						{{ labelFunc(o) }}
					</template>
					<template v-else>
						{{ o[labelProp] }}
					</template>
				</option>
			</select>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AutoComplete',
	data() {
		return {
			filterText: '',
			filteredOptions: [],
			selectValue: Object,
			opened: false
		};
	},
	props: {
		options: Array,
		value: {
			default: null,
			optional: true,
			type: String
		},
		labelProp: String,
		keyProp: String,
		labelFunc: Function
	},
	methods: {
		emitChange() {
			setTimeout(() => this.$emit('change'), 10);
		},
		filter(event) {
			if(!this.filterText) {
				this.filteredOptions = this.options;
				return;
			}

			let input = this.filterText.trim().toLowerCase();
			let frags = input.split(/[\s+]/);
			this.filteredOptions = [];

			for(let i = 0; i < this.options.length; i++) {
				let v = this.options[i];
				let foundNum = 0;
				let allNum = 0;
				for(let j = 0; j < frags.length; j++) {
					let f = frags[j];
					if(f) {
						allNum++;
						if(v.name.trim().toLowerCase().indexOf(f) >= 0) {
							foundNum++;
						}
					}
				}
				if(foundNum == allNum) {
					this.filteredOptions.push(v);
				}
			}
		},
		open() {
			this.opened = true;
			this.$refs.filter.focus();
		},
		close() {
			this.opened = false;
			this.$refs.select.focus();
		}
	},
	mounted() {
		this.selectValue = this.value;
		this.filter(this.filterText);
	},
	watch: {
		options(v) {
			this.filter(this.filterText);
		},
		value(v) {
			this.selectValue = this.value;
		},
		selectValue(v) {
			this.$emit('input', this.selectValue);
		}
	},
	emits: ['input', 'change']
}
</script>

<style scoped>
	.media-prepend {
		background-color: #e9ecef;
		transition: width 0.5s;
		position: relative;
	}
	input[type="text"] {
		padding-left: 40px;
		box-sizing: border-box;
		background-color: #e9ecef;
		transition: background-color 0.5s;
	}
	input[type="text"].opened {
		background-color: #ffffff;
	}
	svg {
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.media-prepend svg {
		color: #555555;
	}
	.select-overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #0086FF;
	}
	.select-overlay {
		color: #ffffff;
	}
</style>
